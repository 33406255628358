import React, { useEffect, useState } from "react";
import { getAllPrice,updateRegisterPrice,updateAdditionalprice } from "../../Service/Apis";
import Loader from "../../Compunents/Loader/Loader";

const PriceManagement = () => {
  const [registrationAmount, setRegistrationAmount] = useState("");
  const [additionalTrainingAmount, setAdditionalTrainingAmount] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [getAllDataPrice, setGetAllDataPrice] = useState([]);
  const [loader, setLoader] = useState(false);

  const getPriceFunction = async () => {
    setLoader(true);
    try {
      let response = await getAllPrice().then((res) => {
        return res.data.data;
      });
      console.log(response);
     setRegistrationAmount(response.find((ree)=>ree.priceFor ==="Registration_Fees").price)
     setAdditionalTrainingAmount(response.find((ree)=>ree.priceFor === "Additional_Training_Fees").price)
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPriceFunction();
  }, []);

  const handleUpdateClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    setLoader(true);
  
    try {
      await updateRegisterPrice({ price: registrationAmount });
      await updateAdditionalprice({ price: additionalTrainingAmount });
  
      getPriceFunction(); 
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };
  
  

  return (
    <>
    {loader && (
        <>
          <Loader />
        </>
      )}
      <div className="main_container_style">
        <div style={{ marginBottom: "30px" }}>
          <div className="main_heading_style">Price Management</div>
          <div>
            Price management involves setting and adjusting prices to attract
            customers while balancing profitability. It aims to optimize sales
            and market competitiveness.
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <label className="sub_heading">Registration Amount</label>
          <div className="row mt-3">
            <div className="col-md-3">
              <label>Registration Amount:</label>
            </div>
            <div className="col-md-3">
              <input
                type="text"
                value={registrationAmount}
                onChange={(e) => setRegistrationAmount(e.target.value)}
                disabled={!isEditing}
                className="form-control"
              />
            </div>
          </div>
          <div className="mt-3">
            Registration Amount refers to the fee participants pay to enroll in
            a program or event. It ensures their place is secured and helps
            cover administrative costs.
          </div>
        </div>

        {/* Additional Training Amount Section */}
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <label className="sub_heading">Additional Training Amount</label>
          <div className="row mt-3">
            <div className="col-md-3">
              <label>Additional Training Amount:</label>
            </div>
            <div className="col-md-3">
              <input
                type="text"
                value={additionalTrainingAmount}
                onChange={(e) => setAdditionalTrainingAmount(e.target.value)}
                disabled={!isEditing}
                className="form-control"
              />
            </div>
          </div>
          <div className="mt-3">
            Additional Training Amount covers the cost of extra training
            sessions beyond the standard program. It ensures participants
            receive the necessary skills for optimal performance.
          </div>
        </div>

        {/* Button at the Bottom-Left */}
        <div style={{ alignSelf: "flex-end", marginLeft: "10px" }}>
          <button
            onClick={isEditing ? handleSaveClick : handleUpdateClick}
            style={{
              padding: "10px 20px",
              backgroundColor: isEditing ? "#906652" : "#234d6f",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "bold",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            {isEditing ? "Save" : "Update"}
          </button>
        </div>
      </div>
    </>
  );
};

export default PriceManagement;
