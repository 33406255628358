import axios from "axios";
import { API, baseUrl } from "./ApiRoutes";

export const getallUser = async () => {
  return await axios.get(`${baseUrl}${API.getallUser}`);
};
export const getUserbyid = async (id) => {
  return await axios.get(`${baseUrl}${API.getUserbyid}${id}`);
};
export const getAllCoupon = async (id) => {
  return await axios.get(`${baseUrl}${API.getAllCoupon}`);
};


export const addCoupon = async (body) => {
  return await axios.post(`${baseUrl}${API.addCoupon}`, body);
};

export const updateCoupon = async (id, body) => {
  return await axios.put(`${baseUrl}${API.updateCoupon}${id}`, body);
};

export const deleteCouponbyid = async (id) => {
  return await axios.delete(`${baseUrl}${API.deleteCouponbyid}${id}`);
};

export const updateActivebyid = async (id, body) => {
  return await axios.put(`${baseUrl}${API.updateActivebyid}${id}`, body);
};

export const getAllPrice = async()=>{
  return await axios.get(`${baseUrl}${API.getAllPrice}`)
}

export const updateRegisterPrice = async(body)=>{
  return await axios.post(`${baseUrl}${API.updateRegisterPriceFees}`,body)
}

export const updateAdditionalprice = async(body)=>{
  return await axios.post(`${baseUrl}${API.updateAdditionalPriceFees}`,body)
}
export const fetchPayments = async () => {
  return await axios.get(`${baseUrl}${API.fetchPayments}`);
};
export const getPaymentHistoryByUserId = async (id) => {
  return await axios.get(`${baseUrl}${API.getPaymentHistoryByUserId}${id}`);
};
