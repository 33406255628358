import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
// import { MdSpaceDashboard } from "react-icons/md";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import User from "../User/User";
import { IoIosArrowDown, IoIosLogOut } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

import { HiUserGroup } from "react-icons/hi2";

import { VscBell } from "react-icons/vsc";
import { PiCoins, PiMoneyWavy } from "react-icons/pi";
import { TbUsers } from "react-icons/tb";
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { PiVideo } from "react-icons/pi";
import { FaHome, FaBook, FaSignOutAlt } from "react-icons/fa";
import { RiCoupon2Line } from "react-icons/ri";

const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

export default function Sidebar({ children }) {
  const matches = useMediaQuery("(min-width:1199px)");
  const [open, setOpen] = useState(true);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const navigate = useNavigate();
  let location = window.location.href;

  function activeRoute(res) {
    if (location.includes(res)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (matches) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [matches]);

  const handleSignOut = () => {
  
    localStorage.clear();
    sessionStorage.clear();

    navigate("/login");
    // window.location.reload();
  };


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          sx={{ background: "white" }}
          position="fixed"
          open={!matches ? false : open}
        >
          <Toolbar className="d-flex justify-content-between align-items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ mr: 2, mt: -1 }}
            >
              <i className="bi bi-list text-black fw-700"></i>
            </IconButton>
            <div
              className="pointer text-black pe-md-4 pe-sm-2 pe-0 mt-1"
              style={{ marginTop: "-10px" }}
            >
              <User />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant={!matches ? "temporary" : "persistent"}
          open={open}
          onClose={() => setOpen(!open)}
        >
          <DrawerHeader>
            <img src="/images/Learn3Ride.png" alt="" className="w-75" />
          </DrawerHeader>
          <Divider />
          <List>
            {/* <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("overview") ? "activeTab" : ""
                } rounded`}
                onClick={() => navigate("/OverView")}
              >
                <ListItemIcon>
                  <TfiLayoutGrid2
                    className={`fs-7 ${
                      activeRoute("overview") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText primary={<div className="w-100 d-flex align-items-center f-14">Overview</div>} />
              </ListItemButton>
            </ListItem> */}

            <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("userManagement") ? "activeTabUser" : ""
                } rounded`}
                onClick={() => setToggle1(!toggle1)}
              >
                <ListItemIcon>
                  <TbUsers
                    className={`fs-6 ${
                      activeRoute("userManagement") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <div className="w-100 d-flex align-items-center f-14">
                      User Management{" "}
                      {toggle1 ? (
                        <IoIosArrowUp className="ms-2" />
                      ) : (
                        <IoIosArrowDown className="ms-2" />
                      )}
                    </div>
                  }
                />
              </ListItemButton>
            </ListItem>
            <div
              className={`px-3 ps-5 ${toggle1 ? "pt-0 " : ""}`}
              style={{
                height: toggle1 ? "50px" : "0px",
                overflow: "hidden",
                transition: "height 0.3s",
              }}
            >
              <List className="">
                <ListItemButton
                  className={`text-black ${
                    activeRoute("allUsers") ? "activeTab" : ""
                  } rounded`}
                  onClick={() => navigate("/userManagement/allUsers")}
                >
                  <ListItemText
                    primary={<div className="f-14">All Users</div>}
                  />
                </ListItemButton>
                {/* <ListItemButton
                  className={`text-black ${
                    activeRoute("activeSubscribers") ? "activeTab" : ""
                  } rounded`}
                  onClick={() => navigate("/userManagement/activeSubscribers")}
                >
                  <ListItemText
                    primary={<div className="f-14">Active Subscribers</div>}
                  />
                </ListItemButton> */}
              </List>
            </div>
            <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("payments") ? "activeTab" : ""
                } rounded`}
                onClick={() => navigate("/payments")}
              >
                <ListItemIcon>
                  <PiCoins
                    className={`fs-6 ${
                      activeRoute("payments") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText primary={<div className="f-14">Payments</div>} />
              </ListItemButton>
            </ListItem>

            <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("PriceConfiguration") ? "activeTabUser" : ""
                } rounded`}
                onClick={() => setToggle2(!toggle2)}
              >
                <ListItemIcon>
                  <TbUsers
                    className={`fs-6 ${
                      activeRoute("PriceConfiguration") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <div className="w-100 d-flex align-items-center f-14">
                      Price Config
                      {toggle2 ? (
                        <IoIosArrowUp className="ms-2" />
                      ) : (
                        <IoIosArrowDown className="ms-2" />
                      )}
                    </div>
                  }
                />
              </ListItemButton>
            </ListItem>
            <div
              className={`px-3 ps-5 ${toggle2 ? "pt-0 " : ""}`}
              style={{
                height: toggle2 ? "100px" : "0px",
                overflow: "hidden",
                transition: "height 0.3s",
              }}
            >
              <List className="">
                <ListItemButton
                  className={`text-black ${
                    activeRoute("CoupenManagement") ? "activeTab" : ""
                  } rounded`}
                  onClick={() =>
                    navigate("/PriceConfiguration/CoupenManagement")
                  }
                >
                  <RiCoupon2Line className="me-2" />
                  <ListItemText
                    primary={<div className="f-14">Coupon</div>}
                  />
                </ListItemButton>
                <ListItemButton
                  className={`text-black ${
                    activeRoute("PriceManagement") ? "activeTab" : ""
                  } rounded`}
                  onClick={() =>
                    navigate("/PriceConfiguration/PriceManagement")
                  }
                >
                  <PiMoneyWavy className="me-2" />
                  <ListItemText
                    primary={<div className="f-14">Fee</div>}
                  />
                </ListItemButton>
              </List>
            </div>

            <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("lessons") ? "activeTab" : ""
                } rounded`}
                onClick={() => navigate("/lessons")}
              >
                <ListItemIcon>
                  <PiVideo
                    className={`fs-6 ${
                      activeRoute("lessons") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText primary={<div className="f-14">Lesssons</div>} />
              </ListItemButton>
            </ListItem>

            <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("notifications") ? "activeTab" : ""
                } rounded`}
                onClick={() => navigate("/notifications")}
              >
                <ListItemIcon>
                  <VscBell
                    className={`fs-6 ${
                      activeRoute("notifications") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<div className="f-14">Notifications</div>}
                />
              </ListItemButton>
            </ListItem>

           

            {/* <ListItem className="pb-0">
              <ListItemButton
                className={`text-black ${
                  activeRoute("settings") ? "activeTab" : ""
                } rounded`}
                onClick={() => navigate("/settings")}
              >
                <ListItemIcon>
                  <TfiLayoutGrid2
                    className={`fs-5 ${
                      activeRoute("settings") ? "" : "text-black"
                    }  `}
                  />
                </ListItemIcon>
                <ListItemText primary={<div className="f-15">Settings</div>} />
              </ListItemButton>
            </ListItem> */}
          </List>

          {/* <li className="">
            <button className="btn btn" onClick={handleLogout}>
            <IoIosLogOut className="fs-6"/> Logout
            </button>
          </li> */}
          <ListItem className="pb-0 border-top border-bottom">
              <ListItemButton
                // className={`text-black ${
                //   activeRoute("payments") ? "activeTab" : ""
                // } rounded`}
                // onClick={() => navigate("/payments")}
                onClick={handleSignOut}
              >
                <ListItemIcon>
                <IoIosLogOut className="fs-6 text-black"/>
                </ListItemIcon>
                <ListItemText primary={<div className="f-14">Sign Out</div>} />
              </ListItemButton>
            </ListItem>
        </Drawer>
        <Main open={!matches ? true : open}>
          <div className={style.mainContainer}>
            <div className={style.topbar}></div>
            <div className={`${style.content} p-md-4 p-sm-3 p-2`}>
              {children}
            </div>
          </div>
        </Main>
      </Box>
    </>
  );
}
