import React, { useEffect, useState } from 'react'
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import TableContainer from '../../Compunents/TableContainer/TableContainer'
import { getMatchingData } from '../../Firebase/CloudFirestore/GetData'
import Loader from '../../Compunents/Loader/Loader'
import { changeDateFormate, paginateData } from '../../Utils/Utils'
import Pagination from '../../Compunents/Pagination/Pagination'
import moment from 'moment'
import { FiFilter } from "react-icons/fi";
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu'
// import StatusFilterModal from './StatusFilterModal'
// import DateFilterModal from './DateFilterModal'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, ModalBody } from 'reactstrap'
import { updateDocument } from '../../Firebase/CloudFirestore/UpdateData'
import { UserManagement } from './UserManagement.css'
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";





export default function AllLessons() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [page, setPage] = useState(0);
    const rowsPerPage = 5
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [noData, setNoData] = useState(false)
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [openDateFilterModal, setOpenDateFilterModal] = useState(false)

    const [statusChangeData, setStatusChangeData] = useState('')
    const [docId, setDocId] = useState('')
    const [openCommentModal, setopenCommentModal] = useState(false)
    const [isActive, setIsActive] = useState(false);
    const [selectedLessons, setSelectedLessons] = useState([]); 
    
    const [lessons, setLessons] = useState([
        { id: 1, lessonName: 'Balancing', publishedOn: '21/12/2024', tutorials: 10, active: true },
        { id: 2, lessonName: 'Breaking', publishedOn: '21/12/2024', tutorials: 15, active: false },
        { id: 3, lessonName: 'Steering', publishedOn: '21/12/2024', tutorials: 15, active: true },
        { id: 4, lessonName: 'Modifications', publishedOn: '21/12/2024', tutorials: 21, active: false },
        { id: 5, lessonName: 'Bike Race Test', publishedOn: '21/12/2024', tutorials: 18, active: true },
        { id: 6, lessonName: 'Bike Stunts', publishedOn: '21/12/2024', tutorials: 5, active: false },
    ]);
    // Function to handle deleting selected Lessons
    const handleDeleteSelected = () => {
        const updatedLessons = lessons.filter(lessons => !selectedLessons.includes(lessons.id));
        setLessons(updatedLessons);
        setSelectedLessons([]); 
    };
    const handleSelectLessons = (id) => {
        if (selectedLessons.includes(id)) {
            setSelectedLessons(selectedLessons.filter(lesId => lesId !== id));
        } else {
            setSelectedLessons([...selectedLessons, id]);
        }
    };


    const handleToggle = () => {
        setIsActive(prev => !prev);
    };



    async function getAllData() {
        try {
            let res = await getMatchingData('lessons', 'userType', '==', 'Lessons')
            if (res.length === 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
            setAllData(res)
            let slicedData = paginateData(page, rowsPerPage, res)
            setData(slicedData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }



    useEffect(() => {
        getAllData()
    }, [])



    useEffect(() => {
        let slicedData = paginateData(page, rowsPerPage, allData)
        setData(slicedData)
    }, [page, rowsPerPage])



    

    function searchData(e) {
        let value = e?.toLowerCase()
        let arr = [...allData]
        if (e == '') {
            let slicedData = paginateData(page, rowsPerPage, allData)
            setData(slicedData)
        } else {
            let newArr = arr?.filter(lessons => lessons?.owner_name?.toLowerCase()?.includes(value) || lessons?.lessoName?.toLowerCase()?.includes(value))
            let slicedData = paginateData(page, rowsPerPage, newArr)
            setData(slicedData)
            if (slicedData.length == 0) {
                setNoData(true)
            } else {
                setNoData(false)
            }
        }
    }


    function changeStatus(e, id) {
        setDocId(id)
        setStatusChangeData(e)
        setopenCommentModal(true)

    }

    async function updateStatus() {
        setLoader(true)
        try {
            await updateDocument('lessons', docId, { status: statusChangeData })

        } catch (err) {
            console.log(err)
        } finally {
            await getAllData()
            setLoader(false)
            setopenCommentModal(false)
            setDocId('')
        }
    }


    function statusChange(key) {
        switch (key) {
            case 'active':
                return 'text-success'
                break;
            case 'inactive':
                return 'text-danger'
                break;
            case 'suspended':
                return 'text-warning'
                break;

            default:
                break;
        }
    }
    const handleLessonClick = (lessonId) => {
        navigate("/lessonsDetails");
    };

    const closeModal = () => {
        setSelectedLessons(null); // Close the modal
    };

    const handleAddLessonClick = () => {
        // Navigate to the Add Lesson page
        navigate("/addLessons");
    };


    return (


        <div className='main_container_style'>

            

            
            <div className='row  justify-content-md-between justify-content-sm-start justify-content-start'>
                <div className='col-md-6 col-sm-12 col-12'>
                    <div className='main_heading_style'>Lessons</div>
                    <h5 className='manage-heading d-md-none d-sm-block d-block'>Manage your Lesssons here</h5>
                </div>
                <div className='col-md-6 col-sm-12 col-12 d-flex gap-3 justify-content-md-end justify-content-sm-start justify-content-start' >

                    <button className="btn-settings" >
                        < IoSettingsOutline /> Lesson Settings
                    </button>


                    <button className="btn-add" onClick={handleAddLessonClick}>
                        <MdAdd /> Add Lesson
                    </button>

                </div>

            </div>
            <h5 className='manage-heading d-md-block d-sm-none d-none'>Manage your Lesssons here</h5>


            <div className=' search-delete row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>

                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 py-3'>


                    <input type='search' className=' form-control w-100 ' placeholder='Search Lessons' onChange={(e) => {
                        if (e.target.value == ' ') {
                            e.target.value = ''
                        } else {
                            searchData(e.target.value)
                        }
                    }} />
                </div>


                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-3 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
                    <button className='btn border  '
                     onClick={handleDeleteSelected}
                    >< RiDeleteBinLine /> Delete</button>


                </div>


            </div>




            <div className='mt-4'>
                <TableContainer>
                    <Table >
                        {noData ? <caption className='text-center'>No Data Found</caption> : ''}
                        <TableHead >
                            <TableRow >


                                <TableCell >Name</TableCell>

                                <TableCell>Published On</TableCell>

                                <TableCell>No. Of Tutorials</TableCell>

                                <TableCell>Status</TableCell>

                                <TableCell>Action</TableCell>



                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lessons?.map((lessons, index) => {
                                return (
                                    <TableRow key={index}
                                        style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#F7F8F9' }}
                                    >

                                        <TableCell  >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input

                                                    type="checkbox" 
                                                    checked={selectedLessons.includes(lessons.id)}
                                                    onChange={() => handleSelectLessons(lessons.id)}/>

                                                <div onClick={() => handleLessonClick(lessons.id)} className='pointer text-capitalize py-4 px-2'>{lessons?.lessonName}</div>
                                            </div>
                                        </TableCell>

                                        <TableCell>{lessons?.publishedOn}</TableCell>

                                        <TableCell>{lessons?.tutorials}</TableCell>

                                        <TableCell>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                
                                                <span className="form-check form-switch">
                                                    <input className='statusbutton'
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id={`flexSwitchCheckDefault-${lessons.id}`}
                                                        checked={lessons.active}
                                                        onChange={() => handleToggle(lessons.id)} />
                                                </span>
                                                <label className="form-check-label" htmlFor={`flexSwitchCheckDefault-${lessons.id}`}>
                                                    {lessons.active ? 'Active' : 'Inactive'}
                                                </label>
                                            </div>
                                        </TableCell>

                                        <TableCell>
                                            {/* <Link 
                                            // to={`/edit-host-driver/${row.id}`}
                                            >
                                            <IoEyeOutline  />
                                        </Link> */}
                                            <IoEyeOutline
                                                className="fs-5 me-3"
                                                sx={{ cursor: "pointer" }}
                                            />
                                            <CiEdit
                                                className="fs-5 gap-3"
                                                sx={{ cursor: "pointer" }}
                                                onClick={handleAddLessonClick}
                                            /></TableCell>




                                    </TableRow>
                                )
                            })}
                        </TableBody>

                    </Table>
                    <Pagination
                        allData={allData}
                        currentPage={page}
                        paginate={(d) => {
                            setPage(d);
                        }}
                        itemPerPage={rowsPerPage}
                    />

                </TableContainer>
            </div>


        </div>
    )
}

