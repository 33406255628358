import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../Compunents/TableContainer/TableContainer";
import Pagination from "../../Compunents/Pagination/Pagination";
import Loader from "../../Compunents/Loader/Loader";
import { fetchPayments } from "../../Service/Apis";
import { paginateData } from "../../Utils/Utils";

export default function Payments() {
  const [paymentsData, setPaymentsData] = useState([]);
  const [page, setPage] = useState(0);
 const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loader, setLoader] = useState(false);
  const [noData, setNoData] = useState(false);
    const [data, setData] = useState([]);
      const [allData, setAllData] = useState([]);
  const navigate = useNavigate();

  const getPayments = async () => {
    setLoader(true);
    try {
      const response = await fetchPayments(); // Fetch data from API
      console.log("API Response:", response.data);

      if (response.data && Array.isArray(response.data.data)) {
        setPaymentsData(response.data.data); // Extract actual data array
        setAllData(response.data.data); 
        setData(paginateData(0, rowsPerPage, response.data.data)); 
        setNoData(response.data.data.length === 0);
      } else {
        setPaymentsData([]);
        setNoData(true);
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
      setPaymentsData([]);
      setAllData([]);
      setNoData(true);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPayments();
  }, []);

  function statusColor(status) {
    switch (status) {
      case "Successful":
        return "text-success";
      case "Pending":
        return "text-warning";
      case "Failed":
        return "text-danger";
      default:
        return "";
    }
  }
  function searchData(e) {
    let value = e.trim().toLowerCase();
    
    if (!value) {
      // Reset to original paginated data
      setData(paginateData(0, rowsPerPage, allData));
      setPage(0);
      setNoData(false);
      return;
    }
  
    let filteredData = allData.filter((res) => {
      let fullName = `${res?.relation_first_name || ""} ${res?.relation_last_name || ""}`.trim().toLowerCase();
      
      return [
        fullName,
        res?.login_email || "",
        res?.relation_phone_no || "",
        res?.order_id || "",
        res?.transaction_id || "",
        res?.paymentfor || ""
      ]
        .some((field) => field.toLowerCase().includes(value));
    });
  
    setData(paginateData(0, rowsPerPage, filteredData));
    setPage(0);
    setNoData(filteredData.length === 0);
  }
  
  return (
    <div className="main_container_style">
      <div className="main_heading_style">Payments</div>
      <div className=" search-delete row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 py-3">
          <input
            type="search"
            className=" form-control w-100 "
            placeholder="Search Users"
            onChange={(e) => searchData(e.target.value)}
          />
        </div>

        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 py-3 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2">
          <button className="btn border  " onClick={handleDeleteSelected}>
            <RiDeleteBinLine /> Delete
          </button>
        </div> */}
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="mt-4">
          <TableContainer>
            <Table>
              {noData ? (
                <caption className="text-center">No Data Found</caption>
              ) : null}
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Payment For</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone No</TableCell>
                  <TableCell>Date/Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((res, index) => (
                    <TableRow
                      key={index}
                      hover
                      className={statusColor(res.status)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#ffffff" : "#F7F8F9",
                      }}
                    >
                      <TableCell>
                        {res.relation_first_name} {res.relation_last_name}
                      </TableCell>
                      <TableCell>{res.order_id}</TableCell>
                      <TableCell>{res.transaction_id}</TableCell>
                      <TableCell>{res.paymentfor}</TableCell>
                      <TableCell>${res.amount}</TableCell>
                      <TableCell>{res.login_email}</TableCell>
                      <TableCell>{res.relation_phone_no}</TableCell>
                      <TableCell>
                        {res.createdAt
                          ? moment(res.createdAt).format(
                              "DD-MM-YYYY , HH:mm:ss"
                            )
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                {/* Fill empty rows if needed */}
                {/* {Array.from({
                  length: Math.max(
                    0,
                    rowsPerPage -
                      paymentsData.slice(
                        page * rowsPerPage,
                        (page + 1) * rowsPerPage
                      ).length
                  ),
                }).map((_, index) => (
                  <TableRow key={`empty-${index}`} style={{ height: 53 }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                ))} */}
              </TableBody>
            </Table>
            <Pagination
              allData={paymentsData}
              currentPage={page}
              // paginate={(d) => setPage(d)}
              paginate={(newPage, newRowsPerPage) => {
                setPage(newPage);
                setRowsPerPage(newRowsPerPage);
              }}
              itemPerPage={rowsPerPage}
            />
          </TableContainer>
        </div>
      )}
    </div>
  );
}
