import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import ToggleSwitch from "./ToggleSwitch";
import "./Price.style.css";
import {
  addCoupon,
  deleteCouponbyid,
  getAllCoupon,
  updateActivebyid,
  updateCoupon,
} from "../../Service/Apis";
import Loader from "../../Compunents/Loader/Loader";
import moment from "moment";

export default function CouponManagement() {
  const [modal, setModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [loader, setLoader] = useState(false);
  const [action, setAction] = useState({ action: "add", id: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  let empty = {
    coupon_name: "",
    coupon_code: "",
    couponType: "Percentage",
    percentage: "",
    amount: "",
    min_amount: "",
  };

  const [formData, setFormData] = useState(empty);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    setLoader(true);
    try {
      const response = await getAllCoupon();
      setCoupons(response.data.data);
    } catch (err) {
      console.error("Error fetching coupons:", err);
    } finally {
      setLoader(false);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
    setAction({ action: "add", id: "" });
    setFormData(empty);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

   
    if (e.target.type === "number" && value.length > 1) {
      value = value.replace(/^0+/, "");
    }

    let numericValue = parseFloat(value);

    setFormData((prevData) => {
      let updatedData = { ...prevData, [name]: value };

      let errors = {};

   
      if (name === "percentage") {
        updatedData.amount = ""; 

      
        if (numericValue > 100) {
          errors.percentageError = "Percentage cannot be more than 100.";
        } else {
          errors.percentageError = "";
        }
      } else if (name === "amount") {
        updatedData.percentage = ""; 
      }

      return { ...updatedData, ...errors };
    });
  };

  const handleAddCoupon = async () => {
    let errors = {};

    // Check required fields
    if (!formData.coupon_name.trim()) {
      errors.coupon_nameError = "Coupon Name is required.";
    }
    if (!formData.coupon_code.trim()) {
      errors.coupon_codeError = "Coupon Code is required.";
    }
    if (!formData.couponType) {
      errors.couponTypeError = "Please select a Coupon Type.";
    }
    let minAmount = parseFloat(formData.min_amount) || 0;
    let percentage = parseFloat(formData.percentage) || 0;
    let amount = parseFloat(formData.amount) || 0;

    if (formData.couponType === "Percentage") {
      if (!formData.percentage.trim()) {
        errors.percentageError = "Percentage is required.";
      } else if (parseFloat(formData.percentage) > 100) {
        errors.percentageError = "Percentage cannot be more than 100.";
      }
    }
    // if (formData.couponType === "Fixed Amount") {
    //   if (!formData.amount.trim()) {
    //     errors.amountError = "Amount is required.";
    //   } else if (parseFloat(formData.amount) < 0) {
    //     errors.amountError = "Amount cannot be negative.";
    //   }
    // }
    if (formData.couponType === "Fixed Amount") {
      if (!formData.amount.trim()) {
        errors.amountError = "Amount is required.";
      } else if (amount < 0) {
        errors.amountError = "Amount cannot be negative.";
      } else if (minAmount <= amount) {
        errors.min_amountError =
          "Minimum amount must be greater than the discount amount.";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormData((prevData) => ({ ...prevData, ...errors }));
      return;
    }
    let cleanedData = { ...formData };

    if (cleanedData.couponType === "Percentage") {
      delete cleanedData.amount; // Remove amount if Percentage is selected
    } else if (cleanedData.couponType === "Fixed Amount") {
      delete cleanedData.percentage; // Remove percentage if Fixed Amount is selected
    }

    try {
      if (action.action === "add") {
        await addCoupon(formData);
        toast.success("Coupon added successfully!");
      } else {
        await updateCoupon(action.id, formData);
        toast.success("Coupon updated successfully!");
      }
      fetchCoupons();
      toggleModal();
    } catch (err) {
      console.error("Error adding coupon:", err);
      toast.error("Something went wrong!");
    }
  };
  const toggleDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async (id) => {
    try {
     await deleteCouponbyid(deleteId);
      toast.success("Coupon deleted successfully!");
      setCoupons((prevCoupons) =>
        prevCoupons.filter((coupon) => coupon.id !== deleteId)
      );
  
      // fetchCoupons();
      // setDeleteModal(false);
    } catch (err) {
      console.error("Error deleting coupon:", err);
      toast.error("Failed to delete coupon!");
    }
    finally {
      setDeleteModal(false);
      setDeleteId(null);
    }
  };

  const toggleActiveStatus = async (id, isActive) => {
    try {
      setCoupons(
        coupons.map((res) =>
          res.id === id ? { ...res, isActive: isActive ? 1 : 0 } : res
        )
      );
      await updateActivebyid(id, { isActive: isActive ? 1 : 0 });
      fetchCoupons();
      toast.success(
        isActive ? "Coupon activated successfully!" : "Coupon deactivated!"
      )
    } catch (err) {
      console.error("Error updating status:", err);
    }
  };

  const filteredCoupons = coupons.filter(
    (coupon) =>
      coupon.coupon_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      coupon.coupon_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
    <ToastContainer position="top-right" autoClose={3000} />
      {loader && <Loader />}
      <div className="main_container_style">
        <h2 className="main_heading_style">Coupon Management</h2>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="col-md-4">
            <Input
              type="search"
              className="form-control w-75"
              placeholder="Search Coupons"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-6 text-end">
            <Button onClick={toggleModal} className="btn-add">
              + Add Coupon
            </Button>
          </div>
        </div>

        <Table className="mt-4">
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              {/* <th>Coupon</th> */}
              <th>Created At</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCoupons.map((coupon, index) => (
              <tr key={coupon.coupon_code}>
                <td>{coupon.coupon_name}</td>
                <td>{coupon.coupon_code}</td>
                {/* <td>{coupon.coupon_type}</td> */}
                <td>
                  {coupon.createdAt
                    ? moment(coupon.createdAt).format("DD/MM/YYYY ,   HH:mm:ss")
                    : "N/A"}
                </td>
                <td>
                  {/* <ToggleSwitch
                  isActive={coupon.isActive === 1}
                  onToggle={() =>
                    toggleActiveStatus(coupon.id, coupon.isActive)
                  }
                /> */}
                  <FormGroup switch>
                    <Input
                      type="switch"
                      role="switch"
                      checked={coupon.isActive === 1}
                      onChange={(e) => {
                        toggleActiveStatus(coupon.id, e.target.checked);
                      }}
                    />
                    <Label check></Label>
                  </FormGroup>
                </td>
                <td>
                  <FaEdit
                    className="text-primary pointer me-2"
                    onClick={() => {
                      setModal(true);
                      let info = { ...coupon };

                      if (info.percentage) {
                        info.couponType = "Percentage";
                      } else {
                        info.couponType = "Fixed Amount";
                      }

                      info.percentage = info.percentage
                        ? info.percentage.toString()
                        : "";
                      info.amount = info.amount ? info.amount.toString() : "";

                      delete info.id;
                      delete info.isActive;
                      delete info.createdAt;
                      // delete info.amount;
                      setAction({ action: "edit", id: coupon.id });
                      setFormData(info);
                    }}
                  />
                  <FaTrash
                    className="text-danger pointer"
                    onClick={() => toggleDeleteModal(coupon.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered>
          <ModalHeader toggle={() => setDeleteModal(false)}>Confirm Deletion</ModalHeader>
          <ModalBody>Are you sure you want to delete this coupon?<br></br>
          This action cannot be undone.</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>
            <Button color="secondary" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal} toggle={toggleModal} centered>
          <ModalHeader toggle={toggleModal}>
            {action.action === "add" ? "Add New Coupon" : "Update Coupon"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Coupon Name</Label>
                <Input
                  type="text"
                  name="coupon_name"
                  value={formData.coupon_name}
                  onChange={handleChange}
                />
                {formData.coupon_nameError && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formData.coupon_nameError}
                  </p>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Coupon Code</Label>
                <Input
                  type="text"
                  name="coupon_code"
                  value={formData.coupon_code}
                  onChange={handleChange}
                />
                {formData.coupon_codeError && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formData.coupon_codeError}
                  </p>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Coupon Type</Label>
                <Input
                  type="select"
                  name="couponType"
                  value={formData.couponType}
                  onChange={handleChange}
                >
                  <option value="Percentage">Percentage</option>
                  <option value="Fixed Amount">Fixed Amount</option>
                </Input>
                {formData.couponTypeError && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formData.couponTypeError}
                  </p>
                )}
              </FormGroup>

              {formData.couponType === "Percentage" && (
                <FormGroup>
                  <Label>Percentage (%)</Label>
                  <Input
                    type="number"
                    name="percentage"
                    value={formData.percentage}
                    onChange={handleChange}
                    min="0"
                    max="100"
                  />
                  {formData.percentageError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {formData.percentageError}
                    </p>
                  )}
                </FormGroup>
              )}

              {formData.couponType === "Fixed Amount" && (
                <FormGroup>
                  <Label>Amount</Label>
                  <Input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    min="0"
                  />
                  {formData.amountError && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {formData.amountError}
                    </p>
                  )}
                </FormGroup>
              )}
              <FormGroup>
                <Label>Minimum Amount</Label>
                <Input
                  type="text"
                  name="min_amount"
                  value={formData.min_amount}
                  onChange={handleChange}
                />
                {formData.min_amountError && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {formData.min_amountError}
                  </p>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleAddCoupon}>
              {action.action === "add" ? "Add" : "Update"}
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
