import React, { useEffect, useState } from "react";
import { getPaymentHistoryByUserId, getUserbyid } from "../../Service/Apis";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";

var styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
  },
  userDetails: {
    marginBottom: "30px",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  studentsSection: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  studentCard: {
    marginBottom: "15px",
    padding: "15px",
    border: "1px solid #eee",
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
};

export default function UserViewPage() {
  const [user, setUser] = useState({});
  const [paymentHistory, setPaymentHistory] = useState([]);
  let { id } = useParams();
  async function getAllData() {
    try {
      // let res = await getMatchingData('users', 'userType', '==', 'AllUsers')
      let res = await getUserbyid(id).then((ress) => ress.data.data);
      setUser(res);
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      //   setLoader(false);
    }
  }
  async function fetchPaymentHistory() {
    try {
      let res = await getPaymentHistoryByUserId(id);
      setPaymentHistory(res.data?.data || []);
      console.log("Payment History Response:", res);
    } catch (err) {
      console.error("Error fetching payment history:", err);
    }
  }

  useEffect(() => {
    getAllData();
    fetchPaymentHistory();
  }, [id]);

  return (
    <div style={styles.container}>
      <div className="d-flex justify-content-center">
        <div className="main_heading_style">User Details</div>
      </div>
      <div style={styles.userDetails} className="mt-2">
        <div className="sub_heading">Personal Information</div>
        <div className="row mt-3">
          <div className="col-xl-4 col-6">
            <span className="view_label">Name:</span>
            <span className="view_label_data ms-2">
              {user?.relation_first_name}
              {user?.relation_last_name}
            </span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Relationship:</span>
            <span className="view_label_data ms-2">{user?.relationship}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Email:</span>
            <span className="view_label_data ms-2">{user?.email}</span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-xl-4 col-6">
            <span className="view_label">Phone:</span>
            <span className="view_label_data ms-2">{user?.phone_no}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Alternate Phone:</span>
            <span className="view_label_data ms-2">{user?.alter_phone_no}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Home Address:</span>
            <span className="view_label_data ms-2">{user?.home_address}</span>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-4 col-6">
            <span className="view_label">Country:</span>
            <span className="view_label_data ms-2">{user?.country}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">State:</span>
            <span className="view_label_data ms-2">{user?.state}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">City:</span>
            <span className="view_label_data ms-2">{user?.city}</span>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-4 col-6">
            <span className="view_label">Zip Code:</span>
            <span className="view_label_data ms-2">{user?.zipCode}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Preferred Time of Day:</span>
            <span className="view_label_data ms-2">
              {user?.preferred_time_of_day}
            </span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Preferred Day:</span>
            <span className="view_label_data ms-2">{user?.preferred_day}</span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-xl-4 col-6">
            <span className="view_label">Preferred Date & Time:</span>
            <span className="view_label_data ms-2">
              {new Date(user?.preferred_date_time).toLocaleString()}
            </span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Park Locations:</span>
            <span className="view_label_data ms-2">{user?.park_locations}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Amount:</span>
            <span className="view_label_data ms-2">${user?.amount}</span>
          </div>
        </div>
      </div>

      <div style={styles.studentsSection}>
        <div className="sub_heading">Students Information</div>
        {user?.students?.length > 0 ? (
          user?.students?.map((student, index) => (
            <div key={student.id} style={styles.studentCard} className="mt-2">
              <div className="mt-2 sub_heading">Student {index + 1}</div>
              <div className="row mt-3">
                <div className="col-xl-4 col-6">
                  <span className="view_label">Name:</span>
                  <span className="view_label_data ms-2">
                    {student.first_name} {student.last_name}
                  </span>
                </div>
                <div className="col-xl-4 col-6">
                  <span className="view_label">Program:</span>
                  <span className="view_label_data ms-2">
                    {student.program}
                  </span>
                </div>
                <div className="col-xl-4 col-6">
                  <span className="view_label">Height:</span>
                  <span className="view_label_data ms-2">{student.height}</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-xl-4 col-6">
                  <span className="view_label">Addition Traning hourse:</span>
                  <span className="view_label_data ms-2">
                    {student.addition_traning === "yes" ? "Yes" : "NO"}
                  </span>
                </div>
                <div className="col-xl-4 col-6">
                  <span className="view_label">Consideration:</span>
                  <span className="view_label_data ms-2">
                    {student.consideration}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No students found for this user?.</p>
        )}
      </div>
      <div style={styles.userDetails} className="mt-2">
        <div className="sub_heading">Fees</div>
        <div className="row mt-3">
          {user?.addtional_traing_Amount && (
            <>
              <div className="col-xl-4 col-6">
                <span className="view_label">Addtional Training Amount:</span>
                <span className="view_label_data ms-2">
                  {user?.addtional_traing_Amount}
                </span>
              </div>
            </>
          )}

          <div className="col-xl-4 col-6">
            <span className="view_label">Registration Amount:</span>
            <span className="view_label_data ms-2">{user?.amount}</span>
          </div>
          <div className="col-xl-4 col-6">
            <span className="view_label">Total Amount:</span>
            <span className="view_label_data ms-2">
              {user?.addtional_traing_Amount + user?.amount}
            </span>
          </div>
          {(user?.dueAmount ?? 0) > 0 && (
            <div className="col-xl-4 col-6">
              <span className="view_label">Due Amount:</span>
              <span className="view_label_data ms-2">
                {user?.addtional_traing_Amount + user?.dueAmount}
              </span>
            </div>
          )}
        </div>
      </div>
      <div style={styles.userDetails} className="mt-2">
        <div className="sub_heading">Payment History</div>
        <TableContainer component={Paper} sx={{ mt: 3, p: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {/* <TableCell>No.</TableCell> */}
            <TableCell>Date</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Order ID</TableCell>
            <TableCell>Payment For</TableCell>
            <TableCell>Amount</TableCell>
  
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(paymentHistory) && paymentHistory.length > 0 ? (
            paymentHistory.map((payment, index) => (
              <TableRow key={index}>
                {/* <TableCell>{index + 1}</TableCell> */}
                <TableCell>{moment(payment?.createdAt).format( "DD-MM-YYYY , HH:mm:ss")}</TableCell>
                <TableCell>{payment?.transaction_id}</TableCell>
                <TableCell>{payment?.order_id}</TableCell>
                <TableCell>{payment?.paymentfor}</TableCell>
                <TableCell>${payment?.amount}</TableCell>
              
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No payment history available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
    </div>
  );
}

