import "./App.css";

import { Route, Routes } from "react-router-dom";
import LoginAuth from "./Auth/LoginAuth";
import Login from "./Pages/Authentication/Login";

import AllUsers from "./Pages/UserManagement/AllUsers";
import ActiveSubscribers from "./Pages/UserManagement/ActiveSubscribers";

import Payments from "./Pages/Payments/Payments";
import PaymentDetailsView from "./Pages/Payments/PaymentDetailsView";

import Lessons from "./Pages/Lessons/Lessons";
import LessonsDetails from "./Pages/Lessons/LessonsDetails";
import AddLessons from "./Pages/Lessons/AddLessons";
import AddTutorials from "./Pages/Lessons/AddTutorials";

import Notifications from "./Pages/Notification/Notifications";
import UserViewPage from "./Pages/UserManagement/UserViewPage";
import CouponManagement from "./Pages/PriceConfiguration/CouponManagement";
import PriceManagement from "./Pages/PriceConfiguration/PriceManagement";

function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<LoginAuth />}>
          <Route path="/userManagement/allUsers" element={<AllUsers />} />

          <Route
            path="/userManagement/activeSubscribers"
            element={<ActiveSubscribers />}
          />

          <Route path="/payments" element={<Payments />} />
          <Route
            path="/payments/paymentDetailsView"
            element={<PaymentDetailsView />}
          />

          <Route path="/lessons" element={<Lessons />} />
          <Route path="/lessonsDetails" element={<LessonsDetails />} />
          <Route path="/addLessons" element={<AddLessons />} />
          <Route path="/addTutorials" element={<AddTutorials />} />
          <Route path="/allUsers/userView/:id" element={<UserViewPage />} />

          <Route path="/notifications" element={<Notifications />} />

          <Route
            path="/PriceConfiguration/CoupenManagement"
            element={<CouponManagement />}
          />
          <Route
            path="/PriceConfiguration/PriceManagement"
            element={<PriceManagement />}
          />

          {/* <Route path='/settings' element={<Settings />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
