import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import TableContainer from "../../Compunents/TableContainer/TableContainer";
import { getMatchingData } from "../../Firebase/CloudFirestore/GetData";
import Loader from "../../Compunents/Loader/Loader";
import { changeDateFormate, paginateData } from "../../Utils/Utils";
import Pagination from "../../Compunents/Pagination/Pagination";
import moment from "moment";
import { FiFilter } from "react-icons/fi";
import DropDownMenu from "../../Compunents/DropDownMenu/DropDownMenu";
import StatusFilterModal from "./StatusFilterModal";
import DateFilterModal from "./DateFilterModal";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { updateDocument } from "../../Firebase/CloudFirestore/UpdateData";
import { UserManagement } from "./UserManagement.css";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosSearch } from "react-icons/io";
import { getallUser } from "../../Service/Apis";

export default function AllUsers() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false);
  const [statusChangeData, setStatusChangeData] = useState("");
  const [docId, setDocId] = useState("");
  const [openCommentModal, setopenCommentModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  
  // const [usersData, setUsersData] = useState()
  const [users, setUsers] = useState([]);
  // Function to handle deleting selected users
  const handleDeleteSelected = () => {
    const updatedUsers = users.filter(
      (users) => !selectedUsers.includes(users.id)
    );
    setUsers(updatedUsers);
    setSelectedUsers([]); // Clear the selection after deletion
  };
  const handleSelectUsers = (id) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter((useId) => useId !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  async function getAllData() {
    try {
      // let res = await getMatchingData('users', 'userType', '==', 'AllUsers')
      let res = await getallUser().then((ress) => ress.data.data);
      console.log(res);
      if (res.length === 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setAllData(res.reverse());
      // let slicedData = paginateData(page, rowsPerPage, res);
      // setData(res);
      setData(res.slice(0, rowsPerPage)); 
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }




  function searchData(e) {
    let value = e.trim().toLowerCase(); // Trim to avoid space-only inputs
  
    if (!value) {
      let paginated = paginateData(0, rowsPerPage, allData); // Reset to first page
      setData(paginated);
      setPage(0); // Reset page
      setNoData(false);
      return;
    }
  
    let filteredData = allData.filter((user) => {
      let fullName = `${user?.relation_first_name || ""} ${user?.relation_last_name || ""}`.trim().toLowerCase();
    
      return[
       fullName,
        user?.email,
        user?.relation_phone_no,
        user?.park_locations,
      ]
        .filter(Boolean) // Remove undefined/null values
        .some((field) => field.toLowerCase().includes(value))
  });
  
    setData(paginateData(0, rowsPerPage, filteredData)); // Reset pagination
    setPage(0);
    setNoData(filteredData.length === 0);
  }
  
  

  function changeStatus(e, id) {
    setDocId(id);
    setStatusChangeData(e);
    setopenCommentModal(true);
  }

  async function updateStatus() {
    setLoader(true);
    try {
      await updateDocument("users", docId, { status: statusChangeData });
    } catch (err) {
      console.log(err);
    } finally {
      await getAllData();
      setLoader(false);
      setopenCommentModal(false);
      setDocId("");
    }
  }

  function statusChange(key) {
    switch (key) {
      case "active":
        return "text-success";
        break;
      case "inactive":
        return "text-danger";
        break;
      case "suspended":
        return "text-warning";
        break;

      default:
        break;
    }
  }
  // const handlePaginate = (newPage, newRowsPerPage) => {
  //   setPage(newPage);
  //   setRowsPerPage(newRowsPerPage);
  // };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    if (allData.length > 0) {
      const paginatedData = allData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
      setData(paginatedData);
    }
  }, [page, rowsPerPage, allData]);
  

  return (
    <>
     {loader && (
            <>
              <Loader />
            </>
          )}
    <div className="main_container_style">
      {/* <Loader open={loader} /> */}
      <div className="main_heading_style">All Users</div>

      <div className=" search-delete row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 py-3">
          <input
            type="search"
            className=" form-control w-100 "
            placeholder="Search Users"
            onChange={(e) => searchData(e.target.value)}
          />
        </div>

        {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 py-3 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2">
          <button className="btn border  " onClick={handleDeleteSelected}>
            <RiDeleteBinLine /> Delete
          </button>
        </div> */}
      </div>

      <div className="mt-4">
        <TableContainer>
          <Table>
            {noData ? (
              <caption className="text-center">No Data Found</caption>
            ) : (
              ""
            )}
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>

                <TableCell>Email</TableCell>

                <TableCell>Mobile Number</TableCell>

                <TableCell>Number of student</TableCell>

                <TableCell>Park Locations</TableCell>
                <TableCell>Due Amount</TableCell>

                <TableCell>Amount Pay</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((users, index) => {
                return (
                  <TableRow
                    key={users.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#ffffff" : "#F7F8F9",
                    }}
                  >
                    <TableCell
                      className="pointer text-capitalize py-4"
                      onClick={() => navigate(`/allUsers/userView/${users.id}`)}
                    >
                      {/* <span className="pe-2">
                        <input
                          type="checkbox"
                          checked={selectedUsers.includes(users.id)}
                          onChange={() => handleSelectUsers(users.id)}
                        />
                      </span> */}
                      {users?.relation_first_name +
                        " " +
                        users?.relation_last_name}
                    </TableCell>

                    <TableCell>{users?.email}</TableCell>

                    <TableCell>{users?.relation_phone_no}</TableCell>

                    <TableCell>{users?.no_student}</TableCell>

                    <TableCell>{users?.park_locations}</TableCell>
                    
                    <TableCell>{users?.dueAmount ? users.dueAmount : "--"}</TableCell>


                    <TableCell>{users?.amount}</TableCell>
                    
                  </TableRow>
                );
              })}
              
            </TableBody>
          </Table>
          <Pagination
            allData={allData}
            currentPage={page}
            paginate={(newPage, newRowsPerPage) => {
              setPage(newPage);
              setRowsPerPage(newRowsPerPage);
              setData(paginateData(newPage, newRowsPerPage, allData));
          }}
            itemPerPage={rowsPerPage}
          />
        </TableContainer>
      </div>
    </div>
    </>
  );
}
