import { TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'

export default function Pagination({ allData, currentPage, paginate,itemPerPage }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const handleChangePage = (event, newPage) => {
        paginate(newPage);
        paginate(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); 
        paginate(0, newRowsPerPage); // 
      };


    useEffect(() => {
        setPage(currentPage)
        setRowsPerPage(itemPerPage)
    }, [currentPage, itemPerPage]);
    return (
        <>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                count={allData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
