import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import DropDownMenu from '../../Compunents/DropDownMenu/DropDownMenu';
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContainer from '../../Compunents/TableContainer/TableContainer';
import Pagination from '../../Compunents/Pagination/Pagination';
import { changeDateFormate, paginateData } from '../../Utils/Utils';
import moment from 'moment';
import { FiFilter } from 'react-icons/fi';

function Notifications() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState()

  const [noData, setNoData] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [openDateFilterModal, setOpenDateFilterModal] = useState(false)
  const [openJobTypeFilterModal, setOpenJobTypeFilterModal] = useState(false)

  const [statusChangeData, setStatusChangeData] = useState('')
  const [actionChangeData, setActionChangeData] = useState('')
  const [docId, setDocId] = useState('')
  const [openCommentModal, setopenCommentModal] = useState(false)

  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])

  function filterByStatus(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.status == data?.status)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenFilterModal(false)
  }
  function filterByJobType(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => res?.jobType == data?.jobType)
    // console.log(data?.jobType);
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenJobTypeFilterModal(false)
  }

  function filterByDate(data) {
    let arr = [...allData]
    let newArr = arr?.filter(res => moment(changeDateFormate(res?.jobPostedDate)).format('YYYY-MM-DD') == data?.date)
    let slicedData = paginateData(page, rowsPerPage, newArr)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    setOpenDateFilterModal(false)
  }

  function clearFilter() {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
    if (slicedData.length == 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }

  }
  function searchData(e) {
    let value = e?.toLowerCase()
    let arr = [...allData]
    if (e == '') {
      let slicedData = paginateData(page, rowsPerPage, allData)
      setData(slicedData)
    } else {
      let newArr = arr?.filter(res => res?.jobTitle?.toLowerCase()?.includes(value))
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }
  return (
   <div className='main_container_style'>
    <Modal size='md' centered isOpen={openCommentModal}>
        <ModalBody>
          <div className='fs-5 mb-2 fw-bold'>FeedBack</div>
          <textarea rows='5' className='form-control mt-3' placeholder='Enter your comment here' />
          <div className='d-flex justify-content-end gap-3 mt-3'>
            <button className='btn btn-sm border' onClick={() => {
              setopenCommentModal(false)
              setStatusChangeData('')
            }}>Cancel</button>
            <button className='btn btn-sm btn-primary'
            //  onClick={() => updateStatus()} 
             >Submit</button>
          </div>
        </ModalBody>
      </Modal>
      {/* <StatusFilterModal open={openFilterModal} setOpen={setOpenFilterModal} submit={filterByStatus} /> */}
      {/* <DateFilterModal open={openDateFilterModal} setOpen={setOpenDateFilterModal} submit={filterByDate} /> */}
      {/* <JobTypeModel open={openJobTypeFilterModal} setOpen={setOpenJobTypeFilterModal} submit={filterByJobType} /> */}
      {/* <Loader open={loader} /> */}
      <div className='main_heading_style'>Notification</div>
      <div className='row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3'>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
          <input type='search' className='form-control w-100' placeholder='Search' onChange={(e) => {
            if (e.target.value == ' ') {
              e.target.value = ''
            } else {
              searchData(e.target.value)
            }
          }} />
        </div>
        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2'>
          <DropDownMenu button={<button className='btn border'><FiFilter /> Filter by</button>}>
            <MenuItem onClick={() => setOpenFilterModal(true)}>Status</MenuItem>
            <MenuItem onClick={() => setOpenDateFilterModal(true)}>Created date</MenuItem>
            <MenuItem onClick={() => setOpenJobTypeFilterModal(true)}>Job Type</MenuItem>
            <hr />
            <MenuItem onClick={() => clearFilter()}>Clear all filters</MenuItem>
          </DropDownMenu>
        </div>
      </div>

      <div className='mt-4'>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
            				
                <TableCell>Notification Type</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Timestamp</TableCell>
                <TableCell>Action</TableCell>
               

              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {

                return (
                  <TableRow hover key={index} >
                    <TableCell className='pointer text-capitalize' onClick={() => navigate(`view/${res?.id}`)}>{res?.jobTitle}</TableCell>
                    <TableCell className='text-capitalize'>{res?.jobLocation}</TableCell>

                    <TableCell>{res?.jobType}</TableCell>
                    <TableCell>{res?.yearOfExperience}</TableCell>
                    {/* {console.log(res?.jobStatus)} */}
                    <TableCell className='text-capitalize' >
                      {/* {res?.status} */}
                      <select className='form-select bg-transparent f-14 px-1' value={res?.status} 
                      // onChange={(e) => changeStatus(e.target.value, res?.id)}
                      >
                        <option className='f-14' value='active'>Active</option>
                        <option className='f-14' value='inactive'>Inactive</option>
                        {/* <option className='f-14' value='suspended'>Suspendend</option> */}
                      </select>
                    </TableCell>
                    <TableCell>{moment(changeDateFormate(res?.jobPostedDate)).format('DD MMM YYYY')}</TableCell>
                    <TableCell>
                    <select className='form-select bg-transparent f-14 px-1' value={res?.status}
                    //  onChange={(e) => changeStatus(e.target.value, res?.id)}
                     >
                        <option className='f-14' value='active'>Approve</option>
                        <option className='f-14' value='inactive'>Reject</option>
                        {/* <option className='f-14' value='suspended'>Suspendend</option> */}
                      </select>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          <Pagination

            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
      </div>
   </div>
  )
}

export default Notifications
