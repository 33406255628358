export let baseUrl = "https://cdc-production-aa1c482a3231.herokuapp.com";
// export const baseUrl = "http://localhost:8080";

export const API = {
  getallUser: "/api/getAllUser",
  getUserbyid: "/api/getUserbyid/",
  addCoupon: "/coupon/addCoupon",
  getAllCoupon: "/coupon/getAllCoupon",
  updateCoupon: "/coupon/updateCouponbyid/",
  deleteCouponbyid: "/coupon/deleteCouponbyid/",
  updateActivebyid: "/coupon/updateActivebyid/",
  getAllPrice: "/price/getAllPrice",
  updateRegisterPriceFees: "/price/updateRegistrationFees",
  updateAdditionalPriceFees: "/price/updateAddtionalFees",
  fetchPayments: "/api/getPaymentHistory",  
  getPaymentHistoryByUserId: "/api/getPaymentHistoryByUserId/",
};
